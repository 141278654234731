import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import { LoginDto } from "../../Dtos/LoginDto";
import { getAxiosConfig, handleError } from "../../Axios/AxiosBase";
import { ApiConstants } from "../../Constants/ApiConstants";
import axios from "axios";
import Cookies from "universal-cookie";
import { CookieKey } from "../../Constants/CookieKey";

function makeid(length : number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

class LoginMobx {
    usernameText : string = ''
    passwordText : string = ''

    constructor() {
        makeAutoObservable(this)
    }

    onUsernameChange = (text: string) => {
        this.usernameText = text
    }
    onPasswordChange = (text: string) => {
        this.passwordText = text
    }

    resetText = () => {
        this.usernameText = ''
        this.passwordText = ''
    }

    onLogin = () => {
        if(this.usernameText.length < 1) {
            toast.error('Vui lòng điền tên đăng nhập')
            return
        }
        if(this.passwordText.length < 1) {
            toast.error('Vui lòng điền mật khẩu')
            return
        }
        var username = this.usernameText
        var password =  this.passwordText
        var devicePrivateKey = makeid(32)
        axios.get<any>('https://api.ipify.org/?format=json').then(function(ipObject) {
            axios.post<LoginDto>(ApiConstants.login, {
                'username': username,
                'password': password,
                'IPAddress': ipObject.data.ip,
                'devicePrivateKey': devicePrivateKey
            }, getAxiosConfig())
                .then(function(response) {
                    const cookie = new Cookies()
                    cookie.set(CookieKey.token, response.data.token)
                    cookie.set(CookieKey.dpk, devicePrivateKey)
                    toast.success('Đăng nhập thành công')
                    window.location.href = '/user'
                })
                .catch(function(error) {
                    toast.error(handleError(error))
                })
        })
    }
}
const LoginStore = new LoginMobx()
export default LoginStore