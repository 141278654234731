import { CookieKey } from "../Constants/CookieKey";
import Cookies from 'universal-cookie';

export function handleError(error: any) : string {
    if(error !== undefined) {
        if(error.response !== undefined) {
            if(error.response.data !== undefined) {
                if(error.response.data.responseException !== undefined) {
                    if(error.response.data.responseException.exceptionMessage !== undefined) {
                        if( typeof error.response.data.responseException.exceptionMessage === 'string' ) {
                            return error.response.data.responseException.exceptionMessage
                        }
                        else {
                            if(error.response.data.responseException.exceptionMessage.errors !== undefined) {
                                var obj = error.response.data.responseException.exceptionMessage.errors
                                var firstKey = Object.keys(obj)[0]
                                return obj[firstKey][0]
                            }
                        }
                    }
                }
            }
        }
    }
    return ''
}


export function getAxiosConfig() {
    const cookies = new Cookies()
    return {
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${cookies.get(CookieKey.token)}`
        }
    }
}

export function createQuery(params: any) : string {
    var result : Array<string> = []
    Object.keys(params).forEach(key => {
        let value = params[key];
        result.push(`${key}=${value}`)
    });
    return result.join('&')
}