export class ApiConstants {
    static baseURL : string = 'https://api-exam.fit-vimaru.vn/'
    static login : string = `${this.baseURL}api/authentication/login`
    static role : string = `${this.baseURL}api/role`
    static user : string = `${this.baseURL}api/user`
    static category : string = `${this.baseURL}api/category`
    static examTopic : string = `${this.baseURL}api/exam-topic`
    static examQuestionGroup : string = `${this.baseURL}api/exam-question-group`
    static examQuestion : string = `${this.baseURL}api/exam-question`
    static contest : string = `${this.baseURL}api/contest`
}