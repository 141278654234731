import React, { ReactNode } from 'react'
import { RequireAuthorized } from './RequireAuthorized'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition, faAngleRight, faBars, faClipboardCheck, faFileLines, faPowerOff, faUser, faUsers, faXmark } from '@fortawesome/free-solid-svg-icons'
import Cookies from 'universal-cookie'
import { CookieKey } from '../../Constants/CookieKey'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react'
import LoadingOverlay from 'react-loading-overlay-ts'

class MainLayout extends React.Component<{
    children: ReactNode,
    title: string,
    actions: ReactNode,
    active: string,
    rightTab: ReactNode,
}> {
    render(): React.ReactNode {
        return <>
        <LoadingOverlay
            active={MainLayoutStore.overlayLoading}
            spinner
            text='Đang tải dữ liệu'
        >
            <RequireAuthorized>
                <header>
                    <div className='header-top'>
                        <h3>Quản trị hệ thống thi</h3>
                    </div>
                    <div className='header-bottom'>
                        <div className='header-bottom-title'>
                            <h5>{this.props.title}</h5>
                        </div>
                        <div className='header-bottom-action'>
                            {this.props.actions}
                        </div>
                    </div>
                </header>
                <div className='left-nav-wrapper'>
                    <div className='left-nav-header'>
                        <img alt='Logo đại học hàng hải' src='/imgs/vmu-logo.png' /> 
                    </div>
                    <div className='left-nav-body'>
                        <div className='left-nav-list'>
                            <LeftNavItem title='Quản lý người dùng' icon={faUser} active={this.props.active === 'user'} url='/user' />
                            <LeftNavItem title='Phân quyền' icon={faUsers} active={this.props.active === 'permission'} url='/permission' />
                            <LeftNavItem title='Quản lý danh mục' icon={faBars} active={this.props.active === 'category'} url='/category' />
                            <LeftNavItem title='Quản lý bộ đề' icon={faFileLines} active={this.props.active === 'examTopic'} url='/exam-topic' />
                            <LeftNavItem title='Quản lý cuộc thi' icon={faClipboardCheck} active={this.props.active === 'contest'} url='/contest' />
                        </div>
                        <div className='left-nav-bottom' onClick={() => {
                            const cookie = new Cookies()
                            cookie.remove(CookieKey.token)
                            cookie.remove(CookieKey.dpk)
                            window.location.href = '/'
                        }}>
                            <FontAwesomeIcon icon={faPowerOff} />
                        </div>
                    </div>
                </div>
                <div className='main-content'>
                    {this.props.children}
                </div>
                <div className={`right-slide-tab ${MainLayoutStore.rightTabToggle ? 'active' : ''}`}>
                    <div className='right-slide-tab-wrapper'>
                        <FontAwesomeIcon icon={faXmark} className='close-icon' onClick={() => {
                            MainLayoutStore.toggleRightBar()
                        }} />
                        {this.props.rightTab}
                    </div>
                </div>
            </RequireAuthorized>
        </LoadingOverlay>
        </>
    }
}

export default observer(MainLayout)

class LeftNavItem extends React.Component<{title : string, icon: IconDefinition, active: boolean, url: string}> {
    render(): React.ReactNode {
        return <> 
        <div className={`left-nav-item ${this.props.active ? 'active' : ''}`} onClick={() => {
            window.location.href = this.props.url
        }}>
            <div className='icon'>
                <FontAwesomeIcon icon={this.props.icon} />
            </div>
            <div className='title'>
                <b>{this.props.title}</b>
            </div>
        </div>
        </>
    }
}

class MainLayoutMobx {
    rightTabToggle : boolean = false
    overlayLoading : boolean = false
    constructor() {
        makeAutoObservable(this)
    }
    toggleRightBar = () => {
        this.rightTabToggle = !this.rightTabToggle
    }
    showLoading = () => {
        this.overlayLoading = true
    }
    hideLoading = () => {
        this.overlayLoading = false
    }
}
export const MainLayoutStore = new MainLayoutMobx()