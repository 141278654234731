import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import React from "react";
import './css/components.css';
import './css/base.css';
import './css/app.css';
import LoginPage from "./Modules/Auth/LoginPage";
import UserPage from "./Modules/User/UserPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Navigate to ="/404" />}/>
        <Route path="/" Component={LoginPage} /> 
        <Route path="/user" Component={UserPage} /> 
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
