export enum EnumApiMethod {
    GET,
    POST,
    PUT,
    DELETE
}
export enum EnumCategory {
    Role = 0,
    Claim = 1,
    Faculty = 2,
    Class = 3,
    Subject = 4,
    ClassGroup = 5,
}
export enum EnumDifficult {
    Easy = 0,
    Medium = 1,
    Hard = 2,
    Advanced = 3,
    Mixed = 4,
}