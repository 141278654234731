import React from 'react'
import { observer } from 'mobx-react'
import LoginStore from './LoginMobx'
import Cookies from 'universal-cookie'
import { CookieKey } from '../../Constants/CookieKey'

class LoginPage extends React.Component<{}> {
    constructor(props: any) {
        super(props)
        const cookie = new Cookies()
        if(cookie.get(CookieKey.token) !== undefined) {
            window.location.href = '/user'
        }
    }
    render(): React.ReactNode {
        return <>
            <div className='login-wrapper'>
                <div className='login-layout'>
                    <form className='login-form' onSubmit={(e) => { 
                        e.preventDefault()
                        LoginStore.onLogin() 
                    }}>
                        <img alt='Logo đại học hàng hải' src='/imgs/vmu-logo.png' />
                        <h2 className='raleway-font'>TRƯỜNG ĐẠI HỌC HÀNG HẢI VIỆT NAM</h2>
                        <h5>Quản trị hệ thống thi</h5>
                        <input placeholder='Tên đăng nhập' value={LoginStore.usernameText} onChange={(event) => {
                            LoginStore.onUsernameChange(event.target.value)
                        }} />
                        <input type='password' placeholder='Mật khẩu' value={LoginStore.passwordText} onChange={(event) => {
                            LoginStore.onPasswordChange(event.target.value)
                        }} />
                        <button type='submit'>Đăng nhập</button>
                    </form>
                </div>
            </div>
        </>
    }
}

export default observer(LoginPage)