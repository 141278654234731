import React from 'react'
import MainLayout, { MainLayoutStore } from '../Layouts/MainLayouts'
import { observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faPen, faPlus } from '@fortawesome/free-solid-svg-icons'
import { UserPageStore } from './UserPageMobx'
import { CustomModal } from '../Layouts/CustomModal'

class UserPage extends React.Component<{}> {

    constructor(props: any) {
        super(props)
        UserPageStore.fetchData()
    }

    render(): React.ReactNode {
        return <>
            <MainLayout 
                title='Quản lý người dùng' 
                actions={
                    <>
                        <button className='custom-button' onClick={() => {
                            UserPageStore.selectUser(undefined)
                        }}>
                            <FontAwesomeIcon icon={faPlus} />&ensp;
                            Thêm người dùng
                        </button>
                        <button className='custom-button' onClick={() => {
                            MainLayoutStore.toggleRightBar()
                        }}>
                            <FontAwesomeIcon icon={faFilter} />
                        </button>
                    </>
                } 
                active='user'
                rightTab={
                    <>
                        <h4>Bộ lọc</h4>
                        <div style={{width: '100%', height: '1px', backgroundColor: '#ccc', margin: '10px 0px'}}></div>
                        <label>Từ khóa</label>
                        <input className='custom-input' value={UserPageStore.keywordText} onChange={(event) => {
                            UserPageStore.onKeywordTextChange(event.target.value)
                        }} />
                        <label>Vai trò</label>
                        <select className='custom-input' value={UserPageStore.roleValue} onChange={(event) => {
                            UserPageStore.onRoleValueChange(event.target.value)
                        }}>
                            <option value=''>Tất cả</option>
                            <option value='ID ADMIN'>Admin</option>
                            <option value='ID USER'>User</option>
                        </select>
                        <hr />
                        <button className='custom-button'>Lọc</button>
                    </>
                }
            >
                <table className='custom-table'>
                    <thead>
                        <tr>
                            <th>STT</th>
                            <th>Tài khoản</th>
                            <th>Họ tên</th>
                            <th>Email</th>
                            <th>Số điện thoại</th>
                            <th>Vai trò</th>
                            <th style={{width: '50px'}}>#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            UserPageStore.userData.map((item) => <>
                                <tr>
                                    <td>{UserPageStore.userData.indexOf(item) + 1}</td>
                                    <td>{item.username}</td>
                                    <td>{item.fullname}</td>
                                    <td>{item.email}</td>
                                    <td>{item.phoneNumber}</td>
                                    <td>{item.role.name}</td>
                                    <td>
                                        <button className='custom-button' onClick={() => {
                                            UserPageStore.selectUser(item)
                                        }}><FontAwesomeIcon icon={faPen} /></button>
                                    </td>
                                </tr>
                            </>)
                        }
                    </tbody>
                </table>
                <CustomModal 
                    active={UserPageStore.modalActive}
                    title={UserPageStore.modalTitle}
                    actions={<>
                        <button className='custom-button' onClick={() => {
                            UserPageStore.updateUser()
                        }}>Xác nhận</button>
                        <button className='custom-button secondary' onClick={() => {
                            UserPageStore.closeModal()
                        }} >Đóng</button>
                    </>}
                >
                    <div className='form-group'>
                        <label>Tài khoản</label>
                        <input className='custom-input' placeholder='Tài khoản' value={UserPageStore.txtEditUsername} onChange={(event) => {
                            UserPageStore.onEditUsernameChange(event.target.value)
                        }} />
                    </div>
                    {
                        UserPageStore.selectedUser === undefined ? <>
                        <div className='form-group'>
                            <label>Mật khẩu</label>
                            <input className='custom-input' placeholder='Mật khẩu' type='password' value={UserPageStore.txtEditPassword} onChange={(event) => {
                                UserPageStore.onEditPasswordChange(event.target.value)
                            }} />
                        </div>
                        </> : <></>
                    }
                    <div className='form-group'>
                        <label>Họ tên</label>
                        <input className='custom-input' placeholder='Họ tên' value={UserPageStore.txtEditFullname} onChange={(event) => {
                            UserPageStore.onEditFullnameChange(event.target.value)
                        }} />
                    </div>
                    <div className='form-group'>
                        <label>Email</label>
                        <input className='custom-input' placeholder='Email' value={UserPageStore.txtEditEmail} onChange={(event) => {
                            UserPageStore.onEditEmailChange(event.target.value)
                        }} />
                    </div>
                    <div className='form-group'>
                        <label>Số điện thoại</label>
                        <input className='custom-input' placeholder='Số điện thoại' value={UserPageStore.txtEditPhoneNumber} onChange={(event) => {
                            UserPageStore.onEditPhoneNumberChange(event.target.value)
                        }} />
                    </div>
                    <div className='form-group'>
                        <label>Vai trò</label>
                        <select className='custom-input'onChange={(event) => {
                            UserPageStore.onEditRoleChange(event.target.value)
                        }} >
                            <option disabled selected={UserPageStore.selectedUser === undefined}> --- Chọn vai trò ---</option>
                            {
                                UserPageStore.listRole.map((item) => <>
                                    <option value={item.guid} selected={UserPageStore.selectedUser !== undefined && UserPageStore.selectedUser.role.guid === item.guid}>{item.name}</option>
                                </>)
                            }
                        </select>
                    </div>
                </CustomModal>
            </MainLayout>
        </>
    }
}

export default observer(UserPage)