import { makeAutoObservable } from 'mobx'
import React, { ReactNode } from 'react'
import Cookies from 'universal-cookie'
import { CookieKey } from '../../Constants/CookieKey'
import axios from 'axios'
import { ApiConstants } from '../../Constants/ApiConstants'
import { getAxiosConfig } from '../../Axios/AxiosBase'

export class RequireAuthorized extends React.Component<{children : ReactNode}> {
    constructor(props : any) {
        super(props)
        RequireAuthorizedStore.checkToken()
    }
    render(): React.ReactNode {
        return <>
            {this.props.children}
        </>
    }
}

class RequireAuthorizedMobx {
    constructor() {
        makeAutoObservable(this)
    }

    checkToken = () => {
        const cookie = new Cookies()
        var token = cookie.get(CookieKey.token)
        axios.put(ApiConstants.login, {
            'token': token
        }, getAxiosConfig()).catch(function(e) {
            const cookie = new Cookies()
            cookie.remove(CookieKey.token)
            cookie.remove(CookieKey.dpk)
            window.location.href = '/'
        })
    }
}

const RequireAuthorizedStore = new RequireAuthorizedMobx()