import axios from "axios"
import { makeAutoObservable } from "mobx"
import { PagedListDto } from "../../Dtos/PagedListDto"
import { UserDto } from "../../Dtos/UserDto"
import { ApiConstants } from "../../Constants/ApiConstants"
import { createQuery, getAxiosConfig, handleError } from "../../Axios/AxiosBase"
import { toast } from "react-toastify"
import { MainLayoutStore } from "../Layouts/MainLayouts"
import { CategoryDto } from "../../Dtos/CategoryDto"
import { EnumCategory } from "../../Constants/Enums"

class UserPageMobx {
    keywordText : string = ''
    roleValue: string = ''
    userData: Array<UserDto> = []
    totalPage: number = 0
    currentPage: number = 1
    pageSize: number = 10
    listRole: Array<CategoryDto> = []

    modalActive : boolean = false
    selectedUser : UserDto | undefined = undefined
    modalTitle : string = ''

    txtEditUsername : string = ''
    txtEditPassword : string = ''
    txtEditFullname : string = ''
    txtEditEmail : string = ''
    txtEditPhoneNumber : string = ''
    txtEditRole : string = ''

    constructor() {
        makeAutoObservable(this)
    }

    onKeywordTextChange = (text: string) => {
        this.keywordText = text
    }
    onRoleValueChange = (text: string) => {
        console.log(text)
        this.roleValue = text
    }

    fetchData = () => {
        MainLayoutStore.showLoading()
        var query = createQuery({
            'keyword': this.keywordText,
            'roleId': this.roleValue,
            'pageNumber': this.currentPage,
            'pageSize': this.pageSize
        })
        axios.get<PagedListDto<UserDto>>(`${ApiConstants.user}?${query}`, getAxiosConfig())
            .then((response) => {
                this.totalPage = response.data.totalPage
                this.userData = response.data.data
                axios.get<PagedListDto<CategoryDto>>(`${ApiConstants.category}/${EnumCategory.Role}?pageSize=100`, getAxiosConfig())
                    .then((response) => {
                        this.listRole = response.data.data
                    })
            })
            .catch((error) => {
                toast.error(handleError(error))
            })
            .finally(() => {
                MainLayoutStore.hideLoading()
            })
    }

    selectUser = (user : UserDto | undefined) => {
        this.modalTitle = user === undefined ? 'Thêm người dùng' : 'Sửa người dùng'
        this.selectedUser = user
        if(user !== undefined) {
            this.txtEditUsername = user.username
            this.txtEditFullname = user.fullname
            this.txtEditEmail = user.email
            this.txtEditPhoneNumber = user.phoneNumber
            this.txtEditRole = user.role.guid
        }

        this.modalActive = true
    }
    resetText = () => {
        this.txtEditUsername = ''
        this.txtEditFullname = ''
        this.txtEditEmail = ''
        this.txtEditPassword = ''
        this.txtEditPhoneNumber = ''
        this.txtEditRole = ''
    }
    closeModal = () => {
        this.resetText()
        this.modalActive = false
    }

    onEditUsernameChange = (text: string) => {
        this.txtEditUsername = text
    }
    onEditPasswordChange = (text: string) => {
        this.txtEditPassword = text
    }
    onEditEmailChange = (text: string) => {
        this.txtEditEmail = text
    }
    onEditFullnameChange = (text: string) => {
        this.txtEditFullname = text
    }
    onEditPhoneNumberChange = (text: string) => {
        this.txtEditPhoneNumber = text
    }
    onEditRoleChange = (text: string) => {
        this.txtEditRole = text
    }

    updateUser = () => {
        if(this.txtEditUsername.length < 3 || this.txtEditUsername.length > 20 ) {
            toast.error('Tên đăng nhập phải từ 3 đến 20 ký tự')
            return
        }
        if(this.selectedUser === undefined && (this.txtEditPassword.length < 3 || this.txtEditPassword.length > 20 )) {
            toast.error('Mật khẩu phải từ 3 đến 20 ký tự')
            return
        }
        if(this.txtEditFullname.length < 1) {
            toast.error('Họ và tên không được để trống')
            return
        }
        if(this.txtEditEmail.length < 1) {
            toast.error('Email không được để trống')
            return
        }
        MainLayoutStore.showLoading()
        if(this.selectedUser !== undefined) {
            axios.put(`${ApiConstants.user}/${this.selectedUser.id}`, {
                'username': this.txtEditUsername,
                'fullname': this.txtEditFullname,
                'email': this.txtEditEmail,
                'phoneNumber': this.txtEditPhoneNumber,
                'roleId': this.txtEditRole
            }, getAxiosConfig())
                .then((response) => {
                    toast.success('Cập nhật người dùng thành công')
                    this.fetchData()
                })
                .catch((error) => {
                    toast.error(handleError(error))
                })
                .finally(() => {
                    MainLayoutStore.hideLoading()
                    this.resetText()
                    this.modalActive = false
                })
        }
        else {
            axios.post(`${ApiConstants.user}`, {
                'username': this.txtEditUsername,
                'password': this.txtEditPassword,
                'fullname': this.txtEditFullname,
                'email': this.txtEditEmail,
                'phoneNumber': this.txtEditPhoneNumber,
                'roleId': this.txtEditRole
            }, getAxiosConfig())
                .then((response) => {
                    toast.success('Tạo mới người dùng thành công')
                    this.fetchData()
                })
                .catch((error) => {
                    toast.error(handleError(error))
                })
                .finally(() => {
                    MainLayoutStore.hideLoading()
                    this.resetText()
                    this.modalActive = false
                })
        }
    }
}

export const UserPageStore = new UserPageMobx()