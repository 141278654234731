import React, { ReactNode } from 'react'

export class CustomModal extends React.Component<{children: ReactNode, active: boolean, title: string, actions: ReactNode}> {
    render(): React.ReactNode {
        return <>
            <div className={`custom-modal-layout ${this.props.active ? 'show' : ''}`}>
                <div className='custom-modal'>
                    <div className='custom-modal-header'>
                        <h4>{this.props.title}</h4>
                    </div>
                    <div className='custom-modal-body'>
                        {this.props.children}
                    </div>
                    <div className='custom-modal-bottom'>
                        {this.props.actions}
                    </div>
                </div>
            </div>
        </>
    }
}